<template>
  <q-btn
    class="mi-btn"
    :class="{ 'q-btn--icon' : iconType }"
    :color="color"
    :dense="dense"
    :disable="disable"
    :fab="fab"
    :flat="flat"
    :loading="loading"
    :outline="outline"
    no-caps
  >
    <!-- Left icon -->
    <mi-icon
      v-if="icon"
      :class="{ 'mi-btn__icon--single': isButtonHasOnlyIcon }"
      :name="icon"
      :left="!isButtonHasOnlyIcon"
      :size="iconSize"
    ></mi-icon>

    <slot></slot>

    <!-- Right icon -->
    <mi-icon v-if="iconRight" :name="iconRight" :size="iconRightSize" right></mi-icon>
  </q-btn>
</template>

<script>
  import MiIcon from '@/packages/@mi-library/MiIcon/MiIcon.vue'

  export default {
    name: 'MiBtn',
    components: {
      MiIcon
    },
    props: {
      color: {
        type: String,
        required: false,
        default: 'primary'
      },
      dense: {
        type: Boolean,
        required: false,
        default: false
      },
      disable: {
        type: Boolean,
        required: false,
        default: false
      },
      iconSize: {
        type: String,
        required: false,
        default: ''
      },
      iconRightSize: {
        type: String,
        required: false,
        default: ''
      },
      fab: {
        type: Boolean,
        required: false,
        default: false
      },
      flat: {
        type: Boolean,
        required: false,
        default: false
      },
      icon: {
        type: String,
        required: false,
        default: undefined
      },
      iconRight: {
        type: String,
        required: false,
        default: undefined
      },
      loading: {
        type: Boolean,
        required: false,
        default: false
      },
      outline: {
        type: Boolean,
        required: false,
        default: false
      },
      iconType: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    computed: {
      isButtonHasOnlyIcon() {
        return !(this.$slots.default || this.iconRight)
      }
    }
  }
</script>
