<template>
  <q-breadcrumbs
    :active-color="activeColor"
    :separator-color="separatorColor"
    class="mi-breadcrumbs"
  >
    <!-- Separator -->
    <template #separator>
      <mi-icon name="caret-right" size="8px"></mi-icon>
    </template>

    <!-- Items -->
    <q-breadcrumbs-el
      v-for="(item, index) in items"
      :key="index"
      :exact="item.exact"
      :disable="item.disabled"
      :label="item[itemLabelKey]"
      :replace="item.replace"
      :to="item[itemToKey]"
      class="mi-breadcrumbs__el"
    ></q-breadcrumbs-el>
  </q-breadcrumbs>
</template>

<script>
  export default {
    name: 'MiBreadcrumbs',
    props: {
      activeColor: {
        type: String,
        required: false,
        default: 'accent'
      },
      itemLabelKey: {
        type: String,
        required: false,
        default: 'label'
      },
      itemToKey: {
        type: String,
        required: false,
        default: 'to'
      },
      items: {
        type: Array,
        required: false,
        default: () => []
      },
      separatorColor: {
        type: String,
        required: false,
        default: 'primary'
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mi-breadcrumbs {
    color: $mi-link-text-color;

    ::v-deep(.q-breadcrumbs__separator) {
      margin-top: 6px;
    }

    &__el[href] {
      color: $mi-red-500;

      &:hover {
        text-decoration: underline;
      }
    }
  }
</style>
