<template>
  <q-btn-group
    class="mi-btn-group"
    :flat="flat"
    :outline="outline"
    :spread="spread"
    :stretch="stretch"
    :unelevated="unelevated"
  >
    <slot></slot>
  </q-btn-group>
</template>

<script>
  export default {
    name: 'MiBtnGroup',
    props: {
      flat: {
        type: Boolean,
        required: false,
        default: false
      },
      outline: {
        type: Boolean,
        required: false,
        default: false
      },
      spread: {
        type: Boolean,
        required: false,
        default: false
      },
      stretch: {
        type: Boolean,
        required: false,
        default: false
      },
      unelevated: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
</script>
