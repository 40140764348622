<template>
  <q-dialog
    class="mi-dialog"
    :auto-close="autoClose"
    :maximized="maximized"
    :no-backdrop-dismiss="noBackdropDismiss"
    :no-esc-dismiss="noEscDismiss"
    :no-route-dismiss="noRouteDismiss"
    :persistent="persistent"
    :position="position"
  >
    <mi-card v-if="!customDesignDialog">
      <mi-card-section
        v-if="closeIcon"
        class="mi-card__section--close-dialog q-px-sm q-pt-sm q-pb-none flex justify-end"
      >
        <mi-btn
          v-close-popup
          icon="close"
          icon-size="18px"
          fab
          flat
          icon-type
          class="mi-close-dialog-button"
        ></mi-btn>
      </mi-card-section>

      <mi-card-section class="q-px-lg" :class="{ 'q-pt-none': closeIcon }">
        <slot></slot>
      </mi-card-section>

      <mi-card-actions v-if="$slots.actions">
        <slot name="actions"></slot>
      </mi-card-actions>

      <mi-inner-loading :showing="innerLoading">
        <mi-spinner size="60px"></mi-spinner>
      </mi-inner-loading>
    </mi-card>

    <mi-card v-else>
      <slot></slot>
    </mi-card>
  </q-dialog>
</template>

<script>
  import MiBtn from '@/packages/@mi-library/MiBtn/MiBtn.vue'
  import MiCard from '@/packages/@mi-library/MiCard/MiCard.vue'
  import MiCardSection from '@/packages/@mi-library/MiCard/MiCardSection.vue'
  import MiCardActions from '@/packages/@mi-library/MiCard/MiCardActions.vue'
  import MiInnerLoading from '@/packages/@mi-library/MiInnerLoading/MiInnerLoading.vue'
  import MiSpinner from '@/packages/@mi-library/MiSpinner/MiSpinner.vue'

  export default {
    name: 'MiDialog',
    components: {
      MiBtn,
      MiCard,
      MiCardActions,
      MiCardSection,
      MiInnerLoading,
      MiSpinner
    },
    props: {
      autoClose: {
        type: Boolean,
        required: false,
        default: false
      },
      closeIcon: {
        type: Boolean,
        required: false,
        default: true
      },
      customDesignDialog: {
        type: Boolean,
        required: false,
        default: false
      },
      innerLoading: {
        type: Boolean,
        required: false,
        default: false
      },
      maximized: {
        type: Boolean,
        required: false,
        default: false
      },
      noBackdropDismiss: {
        type: Boolean,
        required: false,
        default: false
      },
      noEscDismiss: {
        type: Boolean,
        required: false,
        default: false
      },
      noRouteDismiss: {
        type: Boolean,
        required: false,
        default: false
      },
      persistent: {
        type: Boolean,
        required: false,
        default: false
      },
      position: {
        type: String,
        required: false,
        default: 'standard'
      }
    }
  }
</script>

<style lang="scss">
  .mi-dialog .mi-card__section--close-dialog .mi-btn.q-btn {
    min-width: $mi-dialog-close-button-size;
    min-height: $mi-dialog-close-button-size;
  }

  .mi-dialog .mi-card__actions .mi-btn.q-btn {
    padding: $mi-button-padding;
  }

  .mi-card__section--close-dialog + .mi-card__section {
    margin-top: -.5rem;
  }
</style>
