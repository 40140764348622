import { createApp } from 'vue'
import Quasar from 'quasar/src/vue-plugin.js';
import { Amplify, Analytics } from 'aws-amplify'

import { ComponentLibrary } from '@craft-design-system/core-components-vue'
import * as craftComponents from '@craft-design-system/core-components-vue'
import router from './router'
import store from './store'
import i18n from './plugins/i18n'
import quasarOptions from './plugins/quasar'
import awsConfig from '../config/amplify.config'
import { canAccess } from './utils/accessCheck'

import * as components from './packages/@mi-library'
import * as miComponents from './packages/@mi-components'
import * as directives from './directives'

import App from './App.vue'
import './registerServiceWorker'
import '@craft-design-system/core-components/dist/core-components/core-components.css'

// Application styles
import './assets/fonts/iconfont/index.css'
import './styles/index.scss'

// Amplify
Amplify.configure(awsConfig)

// AWS Analytics
Analytics.autoTrack('session', {
  enable: true
})
Analytics.autoTrack('pageView', {
  enable: true,
  type: 'SPA'
})

Analytics.enable()

// App initialization
const app = createApp(App)
  .use(store)
  .use(router)
  .use(Quasar, quasarOptions)
  .use(i18n)
  .use(ComponentLibrary)

// Register all components from MI library
Object.values(components).forEach(component => {
  app.component(component.name, component)
})

// Register all components from MI library
Object.values(miComponents).forEach(component => {
  app.component(component.name, component)
})

// Register all components from Craft Library
for (const [key, component] of Object.entries(craftComponents)) {
  app.component(key, component)
}

// Register all custom directives
Object.keys(directives).forEach(directiveName => {
  app.directive(directiveName, directives[directiveName])
})

// Global variables
app.config.globalProperties.$canAccess = canAccess

// Mount the application
app.mount('#app')
