<template>
  <q-chip
    class="mi-chip"
    :clickable="clickable"
    :color="color"
    :disable="disable"
    :icon="icon"
    :icon-right="iconRight"
    :removable="removable"
    :ripple="ripple"
    :size="size"
    :text-color="textColor"
    square
  >
    <slot></slot>
  </q-chip>
</template>

<script>
  export default {
    name: 'MiChip',
    props: {
      clickable: {
        type: Boolean,
        required: false,
        default: false
      },
      color: {
        type: String,
        required: false,
        default: ''
      },
      disable: {
        type: Boolean,
        required: false,
        default: false
      },
      icon: {
        type: String,
        required: false,
        default: undefined
      },
      iconRight: {
        type: String,
        required: false,
        default: undefined
      },
      removable: {
        type: Boolean,
        required: false,
        default: false
      },
      ripple: {
        type: [Boolean, Object],
        required: false,
        default: false
      },
      size: {
        type: String,
        required: false,
        default: ''
      },
      textColor: {
        type: String,
        required: false,
        default: ''
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mi-chip {
    font-size: $mi-chip-font-size;
    font-family: $mi-typography-font-family-condensed;
    font-weight: 700;
    line-height: 1.5;
    padding: $mi-chip-padding;
    color: $mi-chip-text-color;
    background-color: $mi-chip-background-color;

    ::v-deep(.q-chip__icon) {
      font-size: $mi-chip-icon-size;
    }

    ::v-deep(.q-chip__icon--remove) {
      margin-left: .75rem;
      margin-right: 0;
    }

    ::v-deep(.q-chip__icon--left) {
      margin-right: .75rem;
    }
  }
</style>
