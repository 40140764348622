<template>
  <q-card-actions class="mi-card__actions" :align="align" :vertical="vertical">
    <slot></slot>
  </q-card-actions>
</template>

<script>
  export default {
    name: 'MiCardActions',
    props: {
      align: {
        type: String,
        required: false,
        default: 'right'
      },
      vertical: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mi-card__actions {
    padding: 0 $mi-card-padding $mi-card-padding;

    .q-btn {
      padding: $mi-button-padding;
    }
  }
</style>
