<template>
  <q-infinite-scroll ref="qInfiniteScroll" class="mi-infinite-scroll" :offset="parseInt(offset, 10)">
    <!-- Binding slots -->
    <template v-for="(value, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>
  </q-infinite-scroll>
</template>

<script>
  export default {
    name: 'MiInfiniteScroll',
    props: {
      offset: {
        type: [Number, String],
        required: false,
        default: 250
      }
    },
    methods: {
      poll() {
        this.$refs.qInfiniteScroll?.poll?.()
      },
      reset() {
        this.$refs.qInfiniteScroll?.reset?.()
      },
      resume() {
        this.$refs.qInfiniteScroll?.resume?.()
      },
      setIndex(index) {
        this.$refs.qInfiniteScroll?.setIndex?.(index)
      },
      stop() {
        this.$refs.qInfiniteScroll?.stop?.()
      },
      trigger() {
        this.$refs.qInfiniteScroll?.trigger?.()
      },
      updateScrollTarget() {
        this.$refs.qInfiniteScroll?.updateScrollTarget?.()
      }
    }
  }
</script>
