<template>
  <q-checkbox
    class="mi-checkbox"
    :color="color"
    :disable="disable"
    :label="label"
    :toggle-indeterminate="toggleIndeterminate"
    :toggle-order="toggleOrder"
    dense
  >
    <slot></slot>
  </q-checkbox>
</template>

<script>
  export default {
    name: 'MiCheckbox',
    props: {
      color: {
        type: String,
        required: false,
        default: 'primary'
      },
      disable: {
        type: Boolean,
        required: false,
        default: false
      },
      label: {
        type: String,
        required: false,
        default: ''
      },
      toggleIndeterminate: {
        type: Boolean,
        required: false,
        default: false
      },
      toggleOrder: {
        type: String,
        required: false,
        default: 'tf'
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mi-checkbox {
    font-size: $mi-checkbox-label-font-size;
    line-height: 1.25;
    color: $mi-checkbox-label-text-color;

    ::v-deep(.q-checkbox__inner) {
      font-size: $mi-checkbox-icon-size * 2;
      color: $mi-checkbox-icon-border-color;

      &:before {
        display: none;
      }
    }

    ::v-deep(.q-checkbox__label) {
      padding-left: $mi-checkbox-label-padding-left;
    }

    ::v-deep(.q-checkbox__bg) {
      border-radius: $mi-checkbox-border-radius;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &.disabled,
    &[disabled] {
      color: $mi-silver-300;

      ::v-deep(.q-checkbox__bg) {
        color: $mi-silver-300;
        background-color: currentColor;
      }
    }
  }
</style>
