<template>
  <q-form
    ref="qForm"
    :autofocus="autofocus"
    :greedy="greedy"
    :no-error-focus="noErrorFocus"
    :no-reset-focus="noResetFocus"
  >
    <slot></slot>
  </q-form>
</template>

<script>
  export default {
    name: 'MiForm',
    props: {
      autofocus: {
        type: Boolean,
        required: false,
        default: false
      },
      greedy: {
        type: Boolean,
        required: false,
        default: false
      },
      noErrorFocus: {
        type: Boolean,
        required: false,
        default: false
      },
      noResetFocus: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    methods: {
      async validate() {
        return this.$refs.qForm?.validate?.()
      },
      resetValidation() {
        this.$refs.qForm?.resetValidation?.()
      }
    }
  }
</script>
