<template>
  <button
    v-if="showButton"
    :id="buttonId"
    class="back-to-top"
    @click="scrollToTop"
  >
    <img class="up-arrow-icon" src="@/assets/images/mi-component-icons/arrow-up.svg" alt="arrow-icon" />
  </button>
</template>

<script>
  export default {
    name: 'MiBackToTopBtn',
    props: {
      buttonId: {
        type: String,
        default: 'back-to-top'
      },
      buttonText: {
        type: String,
        default: ''
      },
      element: {
        type: String,
        default: 'tr'
      }
    },
    data() {
      return {
        showButton: false
      }
    },
    mounted() {
      if (!this.isInViewport(document.querySelector(this.element))) {
        this.showButton = true
      }
    },
    unmounted() {
      window.removeEventListener('scroll', this.handleScrollEnable)
    },
    methods: {
      isInViewport(scrollDemo) {
        if (!scrollDemo) return false
        const rect = scrollDemo.getBoundingClientRect()
        return (
          rect.top >= 0
          && rect.left >= 0
          && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
          && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        )
      },
      handleScrollEnable() {
        const scrollDemo = document.querySelector(this.element)
        this.showButton = this.isInViewport(scrollDemo)
      },
      scrollToTop() {
        const firstElement = document.querySelector(this.element)
        if (firstElement) {
          firstElement.scrollIntoView({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
        }
      }
    }
  }
</script>

<style scoped>
  .back-to-top {
    font-size: 16px;
    border: 0;
    outline: 0;
    background-color: #eaedf3;
    cursor: pointer;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 64px;
    width: 64px;
  }

  .back-to-top:hover {
    background-color: #555555;
  }

  .up-arrow-icon {
    height: 18px;
    width: 14px;
    left: 5px;
    top: 3px;
    border-radius: 0;
  }

  .up-arrow-icon:hover {
    display: none;
  }
</style>
