<template>
  <div class="mi-refresh-btn__container">
    <span class="mi-refresh-btn__title text-weight-bold">{{ title }}</span>
    <mi-btn
      class="mi-refresh-btn__btn secondary m-0 p-0"
      flat
      icon="fill"
      icon-type
      icon-size="12px"
      fab
      :disable="isLoading"
      @click="handleClick"
    >
    </mi-btn>
  </div>
</template>

<script>
  export default {
    name: 'MiRefreshBtn',
    props: {
      title: {
        type: String,
        default: '',
        required: false
      },
      isLoading: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    emits: ['clicked-refresh-btn'],
    methods: {
      handleClick() {
        this.$emit('clicked-refresh-btn', true)
      }
    }
  }
</script>

<style  lang="scss" scoped>
  .mi-refresh-btn {
    &__container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    &__title {
      letter-spacing: 0;
      line-height: 1.875rem;
      font-size: 20px;
    }

    &__btn {
      min-width: 1.5rem;
      opacity: .65;
      transition: opacity 100ms ease-out;

      &:hover {
        opacity: 1;
      }

      &:disabled {
        animation: spin 1s linear infinite;
      }

      @keyframes spin {
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
</style>
