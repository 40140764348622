<template>
  <div class="loader loader--solid"></div>
</template>

<script>
  export default {
    name: 'MiLoader'
  }
</script>

<style lang="scss" scoped>
  .loader {
    width: 100%;
    height: 6px;
    overflow: hidden;
    background: $mi-anthracite-800;
    padding: 10px;

    &:after {
      content: " ";
      height: 3px;
      display: block;
      transform-origin: top left;
    }
  }

  .loader--solid {
    &:after {
      width: 25%;
      animation: solidcolours 5s steps(1) infinite, loadthird 2s infinite linear;
      border-radius: 25px;
    }
  }

  @keyframes loadthird {
    0% { transform: translateX(-100px); }
    100% { transform: translateX(276px); }
  }

  @keyframes solidcolours {
    0% { background: $mi-red-500; }
    100% { background: $mi-red-500; }
  }
</style>
