<template>
  <teleport to="body">
    <div
      v-if="modelValue"
      class="custom-notification flex full-width fixed"
      :style="{ ...positionStyle }"
    >
      <div class="custom-notification__content">
        <slot name="inner-content"></slot>
      </div>
    </div>
  </teleport>
</template>

<script>
  import { computed } from 'vue'

  export default {
    name: 'MiNotify',
    props: {
      position: {
        type: String,
        required: false,
        default: 'bottom'
      },
      justify: {
        type: String,
        required: false,
        default: 'end'
      },
      modelValue: {
        type: Boolean,
        default: false
      }
    },
    setup(props) {
      const dynamicPosition = computed(() => ({
        [props.position]: '12px',
        'justify-content': props.justify === 'center' ? 'center' : `flex-${ props.justify }`,
        right: props.justify === 'end' ? '12px' : 'none',
        left: props.justify === 'start' ? '12px' : 'none'
      }))
      return { positionStyle: dynamicPosition.value }
    }
  }
</script>

<style lang="scss">
  .custom-notification {
    z-index: 9999;

    &__content {
      width: 300px;
      box-shadow: 0 0 10px rgba(0, 0, 0, .2);
      border-radius: 4px;
      padding: 8px 16px;
      max-width: 500px;
      background-color: $mi-anthracite-800;
      color: $mi-white;
    }
  }
</style>
