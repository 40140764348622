<template>
  <q-expansion-item
    ref="qExpansionItem"
    class="mi-expansion-item"
    :class="{
      'mi-expansion-item--no-expand-icon': noExpandIcon,
      'mi-expansion-item--outlined': outlined
    }"
    :caption="caption"
    :caption-lines="captionLines"
    :content-inset-level="parseInt(contentInsetLevel)"
    :default-opened="defaultOpened"
    :dense="dense"
    :disable="disable"
    :expand-icon="expandIcon"
    :expand-icon-class="`mi-expansion-item__expand-icon ${ expandIconClass }`"
    :expand-icon-toggle="expandIconToggle"
    :expand-separator="expandSeparator"
    :expanded-icon="expandedIcon"
    :header-class="`mi-expansion-item__header ${ headerClass }`"
    :group="group"
    :icon="icon"
    :label="label"
    :label-lines="labelLines"
    :switch-toggle-side="switchToggleSide"
  >
    <!-- Binding slots -->
    <template v-for="(value, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>
  </q-expansion-item>
</template>

<script>
  export default {
    name: 'MiExpansionItem',
    props: {
      caption: {
        type: String,
        required: false,
        default: undefined
      },
      captionLines: {
        type: [Number, String],
        required: false,
        default: undefined
      },
      contentInsetLevel: {
        type: [Number, String],
        required: false,
        default: undefined
      },
      defaultOpened: {
        type: Boolean,
        required: false,
        default: false
      },
      dense: {
        type: Boolean,
        required: false,
        default: false
      },
      disable: {
        type: Boolean,
        required: false,
        default: false
      },
      expandIcon: {
        type: String,
        required: false,
        default: undefined
      },
      expandIconClass: {
        type: String,
        required: false,
        default: ''
      },
      expandIconToggle: {
        type: Boolean,
        required: false,
        default: false
      },
      expandSeparator: {
        type: Boolean,
        required: false,
        default: false
      },
      expandedIcon: {
        type: String,
        required: false,
        default: undefined
      },
      headerClass: {
        type: String,
        required: false,
        default: ''
      },
      group: {
        type: String,
        required: false,
        default: undefined
      },
      icon: {
        type: String,
        required: false,
        default: undefined
      },
      label: {
        type: String,
        required: false,
        default: undefined
      },
      labelLines: {
        type: [Number, String],
        required: false,
        default: undefined
      },
      noExpandIcon: {
        type: Boolean,
        required: false,
        default: false
      },
      outlined: {
        type: Boolean,
        required: false,
        default: false
      },
      switchToggleSide: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    methods: {
      show() {
        return this.$refs?.qExpansionItem?.show?.()
      },
      hide() {
        return this.$refs?.qExpansionItem?.hide?.()
      },
      toggle() {
        return this.$refs?.qExpansionItem?.toggle?.()
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep(.mi-expansion-item__header) {
    min-height: $mi-expansion-item-min-height;
  }

  .mi-expansion-item ::v-deep(.q-item__section--avatar) {
    min-width: auto;
  }

  ::v-deep(.mi-expansion-item__header.q-item--dense) {
    min-height: $mi-expansion-item-dense-min-height;
  }

  ::v-deep(.mi-expansion-item__expand-icon) .q-expansion-item__toggle-icon {
    font-size: $mi-expansion-item-expand-icon-size;
    margin-top: 2px;
    color: $mi-expansion-item-expand-icon-color;
  }

  ::v-deep(.mi-expansion-item__expand-icon) .q-expansion-item__toggle-focus {
    font-size: $mi-expansion-item-expand-icon-size;

    + .q-expansion-item__toggle-icon {
      margin-top: -.8em;
    }
  }

  .mi-expansion-item--no-expand-icon ::v-deep(.mi-expansion-item__expand-icon) {
    display: none;
  }

  .mi-expansion-item ::v-deep(.q-item__section--side):not(.mi-expansion-item__expand-icon) {
    padding-right: .75rem;
  }

  .mi-expansion-item.q-expansion-item--popup.q-expansion-item--collapsed {
    padding: 0;
  }

  .mi-expansion-item--outlined.q-expansion-item--collapsed:not(:first-child) > ::v-deep(.q-expansion-item__container) {
    border-top-width: 0;
  }

  .mi-expansion-item.q-expansion-item--popup.q-expansion-item--expanded {
    padding: $mi-expansion-item-popup-vertical-space 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &.mi-expansion-item--outlined + .q-expansion-item--collapsed > ::v-deep(.q-expansion-item__container) {
      border-top-width: $mi-expansion-item-outlined-border-width;
    }
  }

  .mi-expansion-item--outlined ::v-deep(.q-expansion-item__content) {
    border-color: inherit;
  }

  .mi-expansion-item--outlined ::v-deep(.q-expansion-item__container) {
    border-color: $mi-expansion-item-outlined-border-color;
    border-width: $mi-expansion-item-outlined-border-width;
    border-style: solid;
  }
</style>
