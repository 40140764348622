<template>
  <q-icon
    class="mi-icon"
    tag="span"
    :color="color"
    :left="left"
    :name="name"
    :right="right"
    :size="size"
  >
    <slot></slot>
  </q-icon>
</template>

<script>
  export default {
    name: 'MiIcon',
    props: {
      color: {
        type: String,
        required: false,
        default: ''
      },
      left: {
        type: Boolean,
        required: false,
        default: false
      },
      name: {
        type: String,
        required: true
      },
      right: {
        type: Boolean,
        required: false,
        default: false
      },
      size: {
        type: String,
        required: false,
        default: ''
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mi-icon.on-right {
    margin-left: .5rem;
  }

  .mi-icon.on-left {
    margin-right: .5rem;
  }
</style>
