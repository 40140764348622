<template>
  <q-card class="mi-card" :bordered="bordered" :flat="flat">
    <slot></slot>
  </q-card>
</template>

<script>
  export default {
    name: 'MiCard',
    props: {
      bordered: {
        type: Boolean,
        required: false,
        default: false
      },
      flat: {
        type: Boolean,
        required: false,
        default: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mi-card.q-card--bordered {
    border-width: $mi-card-border-width;
    border-color: $mi-card-border-color;
  }
</style>
