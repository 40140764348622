<template>
  <q-badge
    class="mi-badge"
    :align="align"
    :color="color"
    :floating="floating"
    :multi-line="multiLine"
    :text-color="textColor"
  >
    <slot></slot>
  </q-badge>
</template>

<script>
  export default {
    name: 'MiBadge',
    props: {
      align: {
        type: String,
        required: false,
        default: 'middle'
      },
      color: {
        type: String,
        required: false,
        default: ''
      },
      floating: {
        type: Boolean,
        required: false,
        default: false
      },
      multiLine: {
        type: Boolean,
        required: false,
        default: false
      },
      textColor: {
        type: String,
        required: false,
        default: ''
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mi-badge {
    font-size: $mi-badge-font-size;
    font-family: $mi-typography-font-family-condensed;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: .1px;
    padding: $mi-badge-padding;
  }
</style>
