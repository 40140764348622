<template>
  <q-file
    ref="qFile"
    class="mi-field mi-file"
    :accept="accept"
    :autofocus="autofocus"
    :clearable="clearable"
    :color="color"
    :dense="dense"
    :disable="disable"
    :hide-bottom-space="!rules"
    :hide-hint="hideHint"
    :hint="hint"
    :label="label"
    :label-slot="labelSlot"
    :lazy-rules="lazyRules"
    :loading="loading"
    :multiple="multiple"
    :outlined="outlined"
    :rules="rules"
    no-error-icon
    stack-label
  >
    <!-- Binding slots -->
    <template v-for="(value, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>
  </q-file>
</template>

<script>
  export default {
    name: 'MiFile',
    props: {
      accept: {
        type: String,
        required: false,
        default: ''
      },
      autofocus: {
        type: Boolean,
        required: false,
        default: false
      },
      clearable: {
        type: Boolean,
        required: false,
        default: false
      },
      color: {
        type: String,
        required: false,
        default: 'primary'
      },
      dense: {
        type: Boolean,
        required: false,
        default: false
      },
      disable: {
        type: Boolean,
        required: false,
        default: false
      },
      hideHint: {
        type: Boolean,
        required: false,
        default: false
      },
      hint: {
        type: String,
        required: false,
        default: undefined
      },
      label: {
        type: String,
        required: false,
        default: undefined
      },
      labelSlot: {
        type: Boolean,
        required: false,
        default: false
      },
      lazyRules: {
        type: [Boolean, String],
        required: false,
        default: true
      },
      loading: {
        type: Boolean,
        required: false,
        default: false
      },
      multiple: {
        type: Boolean,
        required: false,
        default: false
      },
      outlined: {
        type: Boolean,
        required: false,
        default: false
      },
      rules: {
        type: Array,
        required: false,
        default: () => []
      }
    },
    methods: {
      addFiles() {
        this.$refs.qInput?.addFiles?.()
      },
      blur() {
        this.$refs.qInput?.blur?.()
      },
      focus() {
        this.$refs.qInput?.focus?.()
      },
      pickFiles() {
        this.$refs.qFile?.pickFiles?.()
      },
      removeFile() {
        this.$refs.qFile?.removeFile?.()
      },
      resetValidation() {
        this.$refs.qFile?.resetValidation?.()
      },
      validate() {
        return this.$refs.qFile?.validate?.()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mi-file.mi-field ::v-deep(.q-field__prepend) {
    margin-left: -.25rem;
  }
</style>
