<template>
  <q-card-section class="mi-card__section" :horizontal="horizontal">
    <slot></slot>
  </q-card-section>
</template>

<script>
  export default {
    name: 'MiCardSection',
    props: {
      horizontal: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
</script>
