<template>
  <mi-select
    v-model="model"
    :label="label"
    use-input
    hide-selected
    fill-input
    input-debounce="0"
    :options="options"
    option-value="name"
    option-label="name"
    :hint="hint"
    @filter="filterFn"
    @update:model-value="emitUpdateModelValue"
  >
    <template #no-option>
      <mi-list-item>
        <mi-list-item-section class="text-grey">
          No results
        </mi-list-item-section>
      </mi-list-item>
    </template>
    <!-- Option slot -->
    <template #option="{ itemProps, opt }">
      <mi-list-item v-bind="itemProps">
        <mi-list-item-section> {{ getOptionsName(opt) }} </mi-list-item-section>
      </mi-list-item>
    </template>
  </mi-select>
</template>

<script>
  import { ref } from 'vue'

  let allOptions = []

  export default {
    name: 'MiFilterSelect',
    props: {
      values: {
        type: Object,
        required: true
      },
      label: {
        type: String,
        required: false,
        default: ''
      },
      hint: {
        type: String,
        required: false,
        default: ''
      }
    },
    emits: ['update:modelValue'],
    setup() {
      const options = ref(allOptions)

      return {
        model: ref(null),
        options,

        filterFn(val, update) {
          update(() => {
            const needle = val.toLowerCase()
            options.value = allOptions.filter(v => v.name.toLowerCase().indexOf(needle) > -1
              || v.creator.toLowerCase().indexOf(needle) > -1)
          })
        }
      }
    },
    created() {
      allOptions = this.values
    },
    beforeUpdate() {
      allOptions = this.values
    },
    methods: {
      emitUpdateModelValue(val) {
        if (val?.id) this.$emit('update:modelValue', this.model)
      },
      getOptionsName(opt) {
        return `${ opt.name + this.getConvertedDate(opt) }`
      },
      getConvertedDate(opt) {
        if (!opt.createdDate) return ''

        const [date, time] = opt.createdDate.split('T')
        const hora = time.slice(0, 5)
        return ` - ${ date } ${ hora }`
      }
    }
  }
</script>
