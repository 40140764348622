<template>
  <q-header class="mi-header">
    <q-toolbar class="mi-header__toolbar q-px-md">
      <!-- Navigation -->
      <nav class="mi-header__items flex no-wrap col-grow">
        <slot></slot>
      </nav>

      <!-- Items right -->
      <div v-if="$slots['items-right']" class="mi-header__items mi-header__items--right flex no-wrap">
        <slot name="items-right"></slot>
      </div>

      <!-- MAN logo -->
      <div class="flex q-pr-sm">
        <img src="@/assets/images/man-logo.svg" alt="MAN logo" width="106" height="60" />
      </div>
    </q-toolbar>
  </q-header>
</template>

<script>
  export default {
    name: 'MiHeader'
  }
</script>

<style lang="scss" scoped>
  .mi-header {
    background-color: $mi-white;
    color: $mi-anthracite-800;
    box-shadow: inset 0 -1 * $mi-header-border-width 0 $mi-header-border-color;

    &__toolbar {
      min-height: $mi-header-height;
      align-items: stretch;
    }
  }

  .mi-header__app-logo {
    padding: 2px 8px 2px 0;
  }

  .mi-header__items {
    position: relative;
    padding: .5rem;

    > *,
    > ::v-deep(*) {
      align-self: flex-end;
    }

    &--right:before {
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      height: 40px;
      width: $mi-header-border-width;
      background-color: $mi-header-border-color;
    }
  }
</style>
