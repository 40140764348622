<template>
  <div v-if="modelValue" class="overlay-wrapper">
    <div class="overlay"></div>
  </div>
</template>

<script>

  export default {
    name: 'MiOverlay',
    props: {
      modelValue: {
        type: Boolean,
        default: true
      }
    }
  }

</script>

<style lang="scss" scoped>
  .overlay-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-man-anthracite-800, #303c4983);
    z-index: 9998;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
