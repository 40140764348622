<template>
  <q-drawer
    :bordered="bordered"
    :mini="mini"
    :mini-to-overlay="miniToOverlay"
    :mini-width="parseInt(miniWidth)"
    :overlay="overlay"
    :persistent="persistent"
    :side="side"
    :width="parseInt(width)"
    :breakpoint="breakpoint"
    no-swipe-close
    no-swipe-open
  >
    <!-- Binding slots -->
    <template v-for="(value, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>
  </q-drawer>
</template>

<script>
  export default {
    name: 'MiDrawer',
    props: {
      bordered: {
        type: Boolean,
        required: false,
        default: false
      },
      mini: {
        type: Boolean,
        required: false,
        default: false
      },
      miniToOverlay: {
        type: Boolean,
        required: false,
        default: false
      },
      miniWidth: {
        type: [Number, String],
        required: false,
        default: 60
      },
      overlay: {
        type: Boolean,
        required: false,
        default: false
      },
      persistent: {
        type: Boolean,
        required: false,
        default: false
      },
      side: {
        type: String,
        required: false,
        default: 'left'
      },
      width: {
        type: [Number, String],
        required: false,
        default: 300
      },
      breakpoint: {
        type: Number,
        required: false,
        default: 0
      }
    }
  }
</script>
