<template>
  <q-inner-loading class="mi-inner-loading" :showing="showing">
    <slot></slot>
  </q-inner-loading>
</template>

<script>
  export default {
    name: 'MiInnerLoading',
    props: {
      showing: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mi-inner-loading {
    background-color: rgba($mi-white, .8);
    z-index: 10;
  }
</style>
